import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../config/authAxios";

const initialState = { message: "", data: [], status: null, error: "" };
export const deleteFaqAction = createAsyncThunk(
  "delete-faq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.patch(
        `/delete/faqs`,
        { ...data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deleteFaqSlice = createSlice({
  name: "delete-faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteFaqAction.pending, (state) => {
      state.loading = true
    });

    builder.addCase(deleteFaqAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(deleteFaqAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const deleteFaqReducer = deleteFaqSlice.reducer;
