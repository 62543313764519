import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Alert
  } from "@mui/material";
  import React, { useState } from "react";
  import { useForm } from "react-hook-form";
  import { yupResolver } from "@hookform/resolvers/yup";
  import * as yup from "yup";
  import {useDispatch,useSelector} from 'react-redux'
  import Loader from "../../components/Loader";
  import {Toast} from '../../components/Toast'
import { createReferAction } from "../../redux/reducers/meta/createRefer";
import { getAllReferAction } from "../../redux/reducers/meta/getAllRefer";
  const scheduleNotificationSchema = yup.object({
    destinationLink:yup.string().required("Link is required"),
    name:yup.string().required("Name is required"),
      
  
  })
  const CreateReferLink = ({ open, setOpen }) => {
    const [snake,setSnake] = useState(false)
    const {message,status,loading} = useSelector(state=>state.createRefer);
    const dispatch = useDispatch()
    const {
      handleSubmit,
      register,
    } = useForm({resolver:yupResolver(scheduleNotificationSchema)});
    const handleCreateNotification = async (data) => {
     await dispatch(createReferAction({data}))
     await dispatch(getAllReferAction())
     setSnake(true);
     setOpen(false);
    };
    return (
      <>
  
     <div>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
          <Alert sx={{justifyContent:"center"}} severity="error">All Fields are Reuired to create the Refer Link.</Alert>
        <form onSubmit={handleSubmit(handleCreateNotification)}>
          <DialogTitle>
            <h4>Create Refer Link</h4>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" gap={1} p={2}>
              <TextField
                {...register("destinationLink")}
                fullWidth
                name="destinationLink"
                label="Enter Destination Link"
              />
                <TextField
                {...register("name")}
                fullWidth
                name="name"
                label="Enter Influencer Name"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <button
              type="submit"
              className="btn-submit"
              style={{ width: "120px" }}
              disabled ={loading}
            >
              {loading ? <Loader/> : "Submit"}
            </button>
            <button
              className="btn-submit"
              disabled = {loading}
              style={{ width: "120px", background: "red" }}
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          </DialogActions>
          </form>
        </Dialog>
        </div>
        <Toast open={snake} setOpen={setSnake} message={message} status={status}/>
        </>
    );
  };
  
  export default CreateReferLink;
  