import React, { useState } from "react";
import "../Styles/alluser.css";
import { IoMdCloseCircle } from "react-icons/io";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editGenderAction } from "../redux/reducers/user/editGender";
import { motion } from "framer-motion";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { Toast } from "../components/Toast";
import Loader from "../components/Loader";

const userSchema = yup.object({
  gender:yup.number().required("Gender is Required")
})
const EditUserDetails = ({ editDetails, setEditDeatils, userId }) => {
  const { message, status,loading } = useSelector((state) => state.editUserGender);
  const dispatch = useDispatch();
  const { handleSubmit, register ,formState:{errors}} = useForm({resolver:yupResolver(userSchema)});
  const[snake,setSnake] = useState(false)
  const handleSubmitData = async (data) => {

    data.userId = userId;
    console.log(data);
     await dispatch(editGenderAction(data));
    setSnake(true)
    setEditDeatils(false);
  };
  console.log(message);
  return (
    <>
      {editDetails && (
        <div className="edit-user-details">
          <motion.div initial ={{
            y:1000,
          }} animate={{
            y:0
          }} transition={{
            type:"spring",
            stiffness:150,
            damping:15
            
          }} className="userdetail-container">
            <IoMdCloseCircle
              onClick={() => setEditDeatils(false)}
              style={{
                fontSize: "2rem",
                position: "absolute",
                right: "10px",
                top: "10px",
                cursor: "pointer",
              }}
            />
            <form
              className="user-detail-form"
              onSubmit={handleSubmit(handleSubmitData)}
            >
              <div className="gender-div">
                <input
                  type="radio"
                  name="gender"
                  {...register("gender")}
                  value={2}
                />
                <label htmlFor="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  value={1}
                  {...register("gender")}
                />
                <label htmlFor="male">Female</label>
                <input
                  type="radio"
                  name="gender"
                  value={3}
                  {...register("gender")}
                />
                <label htmlFor="male">Others</label>
              </div>
              <label htmlFor="error" style={{
                color:"red"
              }}>
                {errors?.gender?.message}
              </label>
              <button disabled={loading} className="btn-submit">
               {loading ? <Loader/> : "Save"}
              </button>
            </form>
          </motion.div>
        </div>
      )}
   
      <Toast message={message} status={status} open={snake} setOpen={setSnake}/>
      
    </>
  );
};

export default EditUserDetails;
