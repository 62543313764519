import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import { Toast } from "../components/Toast";
import { BigLoader } from "../components/BigLoader";
import { useNavigate } from "react-router-dom";
import { getUserFeedbackAction } from "../redux/reducers/user/getUserFeedback";

const UserFeedBack = () => {
  const [usersFeedback, setUsersFeedback] = useState([]);
  const [totalFilterPages, setTotalFilterPages] = useState(0);
  const [toastOpen, setToastOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { message, loading, status } = useSelector((state) => state.filterUsers);
  const { data, loading: initialData } = useSelector((state) => state.getAllUser);
  const { page, limit } = useSelector((state) => state.tablePagination);

  const column = [
    {
      headerName: "Name",
      property: "name",
      sorting: true,
      minWidth: 200,
    },
    {
      headerName: "dp",
      type: "image",
      property: "",
      minWidth: 200,
    },
    {
      headerName: "Gender",
      type: "gender",
      property: "gender",
      minWidth: 100,
    },

    {
      headerName: "Rating",
      type:'objectValue',
      property: "ratingInfo.rating",
      minWidth: 100,
    },
    {
      headerName: "Feedback",
      type:'objectValue',
      property: "ratingInfo.feedback",
      minWidth: 200,
    },
    {
      headerName: "Created At",
      type:'',
      property: "createdAt",
      minWidth: 200,
    },
  ];

  const getUsersFeedback = async () => {
    try {
      const result = await dispatch(getUserFeedbackAction({ page, limit }));
      const data = result?.payload?.data;
      setUsersFeedback(data?.usersFeedBack || []);
      setTotalFilterPages(data?.totalPages || 0);
    } catch (error) {
      console.error("Failed to fetch user feedback", error);
      setToastOpen(true);
    }
  };

  useEffect(() => {
    getUsersFeedback();
  }, [page, limit]);

  return (
    <>
      <div className="container-2">
        <Header selectedNumber={1} />
        <button className="btn-submit btn-back" onClick={() => navigate(-1)}>
          Back
        </button>
        {loading || initialData ? (
          <BigLoader />
        ) : (
            <Table column={column} data={usersFeedback} redirect="_id"
            />
        )}
      </div>
      <Toast open={toastOpen} message={message} setOpen={setToastOpen} status={status} />
    </>
  );
};

export default UserFeedBack;
