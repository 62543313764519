import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Table from "../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { freePlanLogsAction } from "../redux/reducers/meta/freePlanLogs";
import {BigLoader} from '../components/BigLoader'
import { Card, CardContent } from "@mui/material";
import { getAdminLogsAction } from "../redux/reducers/admin/authLogs";
const AuthLogs = () => {
    const [formData,setFormdata] = useState({})
  const dispatch = useDispatch();
  const {limit ,page} = useSelector(state=>state.tablePagination)
  const { data, loading } = useSelector((state) => state.adminAuthLogs);
  useEffect(() => {
    setFormdata({
      ...formData,
      limit:limit,
      page:page
    })
    dispatch(getAdminLogsAction(formData));
  }, [limit,page]);

  const column =[
    {
        headerName: "AdminID",
        property: "userId",
        minWidth:200
      },
      {
        headerName: "Date",
        property: "createdAt",
        minWidth:300
      },
      {
        headerName: "Method",
        property: "logs",
        minWidth:700
      },
  ]
  return (
    <>
      <Header selectedNumber={4}></Header>
      <div className="container-2">
        <Card sx={{mt:4,bgcolor:"whitesmoke"}}>
          <CardContent>
            <h3>Free Plan Logs</h3>
          </CardContent>
        </Card>
        <Table data={data?.logs} viewButton ={false} column={column} loading={loading} totalPages={data?.totalPages} />
      </div>
      {loading && <BigLoader/>}
    </>
  );
};

export default AuthLogs;
