import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  page: 1,
  limit: 25,
};

const tablePaginationSlice = createSlice({
  name: "tablePagination",
  initialState, 
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
});

export const tablePaginationReducer = tablePaginationSlice.reducer; 
export const { changeLimit, changePage } = tablePaginationSlice.actions;
