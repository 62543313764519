import React, { useState } from "react";
import "../Styles/header.css";
import { FaPowerOff } from "react-icons/fa";
import { PiHandWavingLight } from "react-icons/pi";
import ProfileImage from '../assets/profile.jpg'
import { useDispatch, useSelector } from "react-redux";
import { adminLogoutAction } from "../redux/reducers/admin/logout";
import { Toast } from "./Toast";
import Loader from "./Loader";

const UpperHeader = ({ openMenu }) => {
  const{message,loading,status} = useSelector(state=>state.adminLogout)
  const dispatch = useDispatch();
  const [snake, setSnake] = useState(false);
  const hadleLogout = async () => {
    const logoutAdmin = await dispatch(adminLogoutAction());
    if (logoutAdmin?.payload?.status === 200) {
      localStorage.clear();
      window.location.href = '/'
      return;
    }
  
    setSnake(true);
  };

  return (
    <>
    <nav>
      <div className="container-2 nav-container">
        <div className="profile-div">
          <div className="admin-name">
            <div className="profile-img-div">
              <img src={ProfileImage} alt="Profile" />
            </div>
            <PiHandWavingLight /> <p>Hello ,</p>
            <p>{localStorage.getItem('user')?.toUpperCase()}</p>
          </div>

          {loading ? <Loader/> : <FaPowerOff onClick={hadleLogout}/>}
        </div>
      </div>
    </nav>
    <Toast open={snake} message={message} setOpen={setSnake} status={status} />
    </>
  );
};

export default UpperHeader;
