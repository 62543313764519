import React, { useEffect } from "react";
import Header from "../components/Header";
import Table from "../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { freePlanLogsAction } from "../redux/reducers/meta/freePlanLogs";
import {BigLoader} from '../components/BigLoader'
import { Card, CardContent } from "@mui/material";
const FreePlanLogs = () => {
  const dispatch = useDispatch();
  const {limit ,page} = useSelector(state=>state.tablePagination)
  const { data, loading } = useSelector((state) => state.freePlanLogs);
  useEffect(() => {
    dispatch(freePlanLogsAction({limit,page}));
  }, [limit,page]);

  const column =[
    {
        headerName: "AdminID",
        property: "adminId",
      },
      {
        headerName: "Status",
        property: "planActivated",
        type:"freePlanLogs"
      },
      {
        headerName: "Date",
        property: "date",
      },
      {
        headerName: "IP",
        property: "ip",
      },
  ]
  return (
    <>
      <Header selectedNumber={1}></Header>
      <div className="container-2">
        <Card sx={{mt:4,bgcolor:"whitesmoke"}}>
          <CardContent>
            <h3>Free Plan Logs</h3>
          </CardContent>
        </Card>
        <Table data={data?.getFreePlan} viewButton ={false} column={column} loading={loading} totalPages={data?.totalPages} />
      </div>
      {loading && <BigLoader/>}
    </>
  );
};

export default FreePlanLogs;
