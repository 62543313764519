import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../config/authAxios";

const initialState = { message: "", data: [], status: null, loading: false };
export const editGenderAction = createAsyncThunk(
  "edit-gender",
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.patch(`/change-gender`, { ...data });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const editGenderSlice = createSlice({
  name: "edit-gender",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(editGenderAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(editGenderAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(editGenderAction.rejected, (state, action) => {
      state.loading = false;

      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const editGenderReducer = editGenderSlice.reducer;
