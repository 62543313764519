import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../config/authAxios";
const initialState = { message: "", data: [], status: null, error: "" };
export const addPrivacyURLAction = createAsyncThunk(
  "add-privacy",
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.patch(
        `/add-privacy`,
        { ...data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addPrivacySlice = createSlice({
  name: "add-privacy-url",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPrivacyURLAction.pending, (state) => {
      state.loading = true
    });

    builder.addCase(addPrivacyURLAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(addPrivacyURLAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const addPrivacyUrlReducer = addPrivacySlice.reducer;
