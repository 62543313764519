import React, { useEffect, useState } from "react";
import "../../Styles/editpremium.css";
import { MdDiscount } from "react-icons/md";
import { FaCalendarDay } from "react-icons/fa6";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getMetaDataAction } from "../../redux/reducers/meta/getMetaData";
import Loader from "../../components/Loader";
import { Toast } from "../../components/Toast";
import { updateSubscriptionAction } from "../../redux/reducers/meta/updateSubscriptions";
import Header from "../../components/Header";
import { singleCountryAction } from "../../redux/reducers/meta/singleCountry";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { changeLimit, changePage } from "../../redux/table/pagination";


const EditPricing = ({ toogleEditPremium }) => {
  const [formData, setFormData] = useState({});
  const [editPremium, setEditPremium] = useState(null);
  const [snake, setSnake] = useState(false);
const navigate = useNavigate()
  const { countryId } = useParams();
  const { data: metaData } = useSelector((state) => state.getMetaData);
  const {data:countryData} = useSelector((state) => state.singleCountry);
  const { loading, message, status } = useSelector(
    (state) => state.updateSubscription
  );
  const dispatch = useDispatch();

  const toggleEdit = (val) => {
    return setEditPremium(val);
  };

  useEffect(() => {
    if (formData.amount && formData.discountPercentage) {
      const discountPercentage =
        (formData?.amount * (100 - formData.discountPercentage)) / 100;
      setFormData((prev) => ({
        ...prev,
        finalAmount: discountPercentage,
      }));
    }
  }, [formData.amount, formData.discountPercentage]);


  const updateSubscription = async (val) => {
    formData.metaId = metaData._id;
    formData.subscriptionId = val;
    console.log(formData);
    await dispatch(updateSubscriptionAction(formData));
    dispatch(getMetaDataAction());
    setFormData({});
    setEditPremium(null);
    setSnake(true);
  };
  console.log(countryData)

  useEffect(() => {
    dispatch(singleCountryAction({ countryId: countryId}))
  }, [countryId]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const cancelEdit = () => {
    setEditPremium(null);
    setFormData({});
  };


  return (
    <>
        <Header/>
        <div className="container-2 edit-premium-container">
        <button className="btn-submit btn-back" onClick={() => navigate(-1)} >Back</button>
          <Stack direction={"row"} spacing={2} mt={10}>
          <h4>Country Name : <span style={{opacity:0.5}}>{countryData?.country}</span></h4>
        <h4>Currency Code : <span style={{opacity:0.5}}>{countryData?.currencySymbol}</span></h4>
          </Stack>
       
        <div className="plan-container">
          {/* Day Wise Plan */}
          {countryData  && countryData?.priceInfo?.map((val, i) => (
            <div className="small-palan-container">
              <div className="small-image-div">
                {val?.finalAmount === 0 ? (
                  <img src="/images/free.jpeg" alt="free" />
                ) : (
                  <img src="/images/small.jpg" alt="" />
                )}
              </div>
              <div className="plan-title">
                <p
                  style={{
                    opacity: 0.5,
                  }}
                >
                  Starts At
                </p>
                <div className="plan-amount-div">
                  <h1> {countryData?.currencySymbol || "₹"} {val?.finalAmount}</h1>{" "}
                  <p
                    style={{
                      opacity: 0.5,
                    }}
                  >
                    Per {val?.duration} days
                  </p>
                </div>
              </div>
              <div className="plan-details">
                <div className="discount-div">
                  <MdDiscount className="discount-icon" />
                  <p
                    style={{
                      opacity: 0.5,
                    }}
                  >
                    Discount
                  </p>
                  {i === editPremium ? (
                    <input
                      type="number"
                      name="discountPercentage"
                      value={formData.discountPercentage}
                      className="edit-plan-input"
                      onChange={(e) => handleInputChange(e)}
                    />
                  ) : (
                    <h2> {val?.discountPercentage} % </h2>
                  )}
                </div>
                <div className="discount-div">
                  <RiMoneyDollarCircleFill className="discount-icon" />
                  <p
                    style={{
                      opacity: 0.5,
                    }}
                  >
                    Amount
                  </p>
                  {i === editPremium ? (
                    <input
                      name="amount"
                      type="number"
                      value={formData?.amount}
                      className="edit-plan-input"
                      onChange={(e) => handleInputChange(e)}
                    />
                  ) : (
                    <h2> {countryData?.currencySymbol || "₹"}  {val?.amount} </h2>
                  )}
                </div>
                <div className="discount-div">
                  <FaCalendarDay className="discount-icon" />
                  <p
                    style={{
                      opacity: 0.5,
                    }}
                  >
                    Duration
                  </p>
                  {i === editPremium ? (
                    <input
                      name="duration"
                      type="number"
                      value={formData?.duration}
                      className="edit-plan-input"
                      onChange={(e) => handleInputChange(e)}
                    />
                  ) : (
                    <h2> {val?.duration} Days</h2>
                  )}
                </div>
              </div>

              {i === editPremium ? (
                <div className="save-plan-div">
                  <button
                    className="btn-submit btn-plan-save"
                    onClick={() => updateSubscription(val._id)}
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Save"}
                  </button>

                  <button
                    disabled={loading}
                    className="btn-submit btn-plan-back"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="btn-submit btn-plan-edit"
                  onClick={() => toggleEdit(i)}
                >
                  EDIT
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      <Toast
        setOpen={setSnake}
        open={snake}
        message={message }
        status={status}
      />
    </>
  );
};

export default EditPricing;
