import React from "react";
import "../Styles/header.css";
import { HiOutlineChevronUp } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

const CustomLink = ({
  slectedFaq,
  titleNumber,
  showContent,
  title,
  content,
  arrow,
  nested,
  icon,
  isOpen,
  selectedNumber,
  path,
}) => {

  const navigate = useNavigate()
  return (
    <>
      <div className="faq-title" onClick={!nested ? ()=>navigate(path) : showContent}>
        <h2>
          {icon}
          {!nested ? <Link to={path}>{title}</Link> : title}
        </h2>
        <p>
          {titleNumber === slectedFaq && nested ? (
            arrow
          ) : selectedNumber === titleNumber ? (
            arrow
          ) : nested ? (
            <HiOutlineChevronUp></HiOutlineChevronUp>
          ) : null}
        </p>
      </div>
      <div
        className="faq-content"
        style={{
          height:
            titleNumber === slectedFaq
              ? "auto"
              : titleNumber === selectedNumber
              ? "auto"
              : "",
        }}
      >
        <div className="faq-inner-content">{content}</div>
      </div>
    </>
  );
};

export default CustomLink;
