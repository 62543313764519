import axios from "axios";

export const authAxios = axios.create({
    // baseURL:'http://localhost:4000/chat',
    // jj
    baseURL:`https://serveradmin.mysterio.chat/chat`,
    headers:{
        'Content-Type':"application/json"
    },
    withCredentials:true
});
authAxios.interceptors.response.use((config)=>{return config},((error)=>{
    let status = error?.response?.status;
    if(status === 401){
        localStorage.clear();
        window.location.href = '/'
    }
    return Promise.reject(error)
}))