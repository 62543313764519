import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { BiCopy } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BiSolidUserAccount, BiSolidUserBadge } from "react-icons/bi";
import { authAxios } from "../config/authAxios";
import { useDispatch, useSelector } from "react-redux";
import { blockUserAction } from "../redux/reducers/user/blockUser";
import Loader from "../components/Loader";
import { Toast } from "../components/Toast";
import EditUserDetails from "../Users/EditUserDetails";
import { MdDelete } from "react-icons/md";
import { Button } from "@mui/material";
import EditPermission from "./EditPermission";
import { removeAdminPermissionAction } from "../redux/reducers/admin/removePermission";
import { blockAdminAction } from "../redux/reducers/admin/blockAdmin";

const ViewMoreAdmin = () => {
  // states
  const [users, setUsers] = useState({});
  const [details, setDetails] = useState();
  const[snake,setSnake] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const { userId } = useParams();
  const dispatch = useDispatch();
  const{data,loading,message,status} = useSelector(state=>state.removePermission)
  const {data:blockData,loading:blockLoading,message:blockMessage,status:blockStatus} = useSelector(state=>state.blockAdmin)

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response =  await authAxios.get(`/get/admin/single/${userId}`)
        setUsers(response?.data?.data);
        setDetails(response.data.user._id);
      } catch (error) {
        console.log(error);
      }
    };
     getUserData();
    document.title = "Mysterio | View More";
  }, [userId,,editPermissions,blockData,data]);


//  copy fn
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(details);
      alert("Id copied to clipboard ");
    } catch (error) {
      alert("Copy failed. Please try again.");
    }
  };

  // block fn
  const handleBlock = async () => {
    await dispatch(blockAdminAction({adminId:userId}))
    setSnake(true)
  };

  const removePermission = async (value) => {
    let data ={
      adminId:userId,
      permission:value
    }
  await dispatch(removeAdminPermissionAction(data))
  
    setSnake(true)

    }

  return (
    <>
      <section className="view-more">
        <button
          className="btn-submit btn-back"
          onClick={() => window.top.close()}
        >
          Back
        </button>
        <div style={{
          paddingBlock:"40px"
        }} className="container alluser-container">
          <div className="view-user-container">
            <div className="total-users location-div">
              <h4 className="icon-heading">
                <FaLocationDot></FaLocationDot> Country
              </h4>
              <p> {users?.country ? users.country : "India"} </p>
            </div>
            <div className="total-users location-div">
              <h4 className="icon-heading">
                <BiSolidUserAccount />
                Account Created
              </h4>
              <p>{users?.createdAt}</p>
            </div>
            <div className="total-users location-div">
              <h4 className="icon-heading">
                <BiSolidUserBadge />
                Admin Id
              </h4>
              <p>{users?._id}</p>
            </div>
            <div className="total-users location-div">
              <h4 className="icon-heading">
                <BiSolidUserBadge />
                Last Login At
              </h4>
              <p>{users?.lastLoginAt}</p>
            </div>
            <div className="total-users location-div">
              <h4 className="icon-heading">
                <HiOutlineDevicePhoneMobile />
                Device Id
              </h4>
              <p>{users?.deviceId || "Not Available"}</p>
            </div>
            <div className="total-users location-div">
              <h4 className="icon-heading">
                <HiOutlineDevicePhoneMobile />
                Ip Address
              </h4>
              <p>{users?.ip || "Not Available"}</p>
            </div>
            <div className="total-users location-div">
              <h4 className="icon-heading">
                <HiOutlineDevicePhoneMobile />
                Created By
              </h4>
              <p>{users?.createdBy || "Not Available"}</p>
            </div>
            <div className="total-users location-div" style={{ width: "100%",position:"relative" }}>
             <Button onClick={()=>setEditPermissions(true)} sx={{position:"absolute", left:10,top:10}} startIcon ={"+"}>Permissions</Button>
              <h4 className="icon-heading">
                <HiOutlineDevicePhoneMobile />
                Permissions
              </h4>
              <div style={{display:"flex",gap:"15px",flexDirection:"row"}}>
              {
                users?.permissions?.map((permission,index)=>
              <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"5px"}}>
                <MdDelete onClick={()=>removePermission(permission)} style={{color:"red",cursor:"pointer"}}/>
                <p key={index}>{permission}</p>
                </div>
                )
              }
              </div>
             
            </div>
          </div>
          <div className="user-name-container">
            <div className="user-image">
              {users.dp ? (
                <img src={users.dp} alt="" />
              ) : (
                <img src="/images/profile.jpg" alt="" />
              )}
            </div>
            <div className="user-details">
              <div className="single-user-name-div">
                <div className="view-more-detail">
                  <h4>Name:</h4> <p>{users.name?.toUpperCase()}</p>
                </div>
              </div>
              <div className="single-user-name-div">
                { users?.isBlocked ? (
                  <button
                    onClick={handleBlock}
                    className="btn-submit btn-unblock"
                  >
                        {loading ? <Loader/> : ` Unblock`}  
                  </button>
                ) : (
                  <button
                    className="btn-submit btn-block"
                    onClick={handleBlock}
                  >
                   {blockLoading ? <Loader/> : ` Block`}
                  </button>
                )}
                <button
                  className="btn-submit btn-copy"
                  onClick={copyToClipboard}
                >
                  Copy <BiCopy></BiCopy>
                </button>
              </div>
              <p
                style={{
                  fontStyle: "italic",
                  opacity: 0.6,
                }}
              >
                Please Do Not Block User Without Verifying With Your Senior.
              </p>
            </div>
          </div>
        </div>
      </section>
     <EditPermission adminId={userId} open={editPermissions} setOpen={setEditPermissions}/>
      <Toast message={message || blockMessage } status={status || blockStatus} open={snake} setOpen={setSnake}/>
    </>
  );
};

export default ViewMoreAdmin;
