import React from 'react'
import '../Styles/loader.css'
const Loader = () => {
  return (
    <div className='loader-container'>
        
    </div>
  )
}

export default Loader