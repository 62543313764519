import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllBlogsAction } from "../../redux/reducers/meta/blog/getAllBlogs";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { BigLoader } from "../../components/BigLoader"; 
import {  useNavigate } from "react-router-dom";

// import { Toast } from "../../components/Toast"; // Commented out since it's not being used

const ViewAllBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { data, loading, message, status } = useSelector((state) => state.getAllBlogs);
  const blogs = data?.blogsData || [];
  useEffect(() => {
    dispatch(getAllBlogsAction());
  }, [dispatch]);

  const column = [
    {
      headerName: "Title",
      type: "",
      property: "title",
      minWidth: 80,
    },
    {
      headerName: "Meta Description",
      type: "",
      property: "metaDesc",
      minWidth: 100,
    },
    {
      headerName: "Reading Time",
      type: "",
      property: "readingTime",
      minWidth: 200,
    },
    {
      headerName: "Image",
      type: "bigImage",
      property: "image",
      minWidth: 200,
    },
    {
      headerName: "Meta URL",
      type: "link",
      property: "metaUrl",
      sorting: true,
      minWidth: 200,
    },
    {
      headerName: "Content",
      type: "html",
      property: "content",
      minWidth: 250,
    },
    {
      headerName: "Description",
      type: "html",
      property: "description",
      minWidth: 250,
    },
    {
      headerName: "Category",
      type: "",
      property: "category",
      minWidth: 250,
    },
  ];

  return (
    <>
      <Header />
      <div className="container-2">
        <div className="btn-open-div">
      <button className="filter-btn"
      onClick={()=>navigate("/create/blog")}
      >Add Blog</button>
        </div>

        {loading ? (
          <BigLoader />
          ) : (
            <>
            <Table data={blogs} column={column} redirect="_id"
            viewButton={false}
            editButton={true} 
            editRedirectTo="/edit/blog" />
            </>

        )}
      </div>
      {/* <Toast message={message} status={status} open={!!message} /> */}
    </>
  );
};

export default ViewAllBlog;