// import React, { useEffect, useState } from "react";
// import { AiFillCaretDown } from "react-icons/ai";
// import { Link } from "react-router-dom";
// import noDataImage from "../assets/nodata.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { changeLimit, changePage } from "../redux/table/pagination";
// const Table = ({
//   data = [],
//   column = [],
//   loading = true,
//   redirect = "_id",
//   totalPages =1,
//   viewButton = true,
//   query ="",
//   redirectTo = "/viewmore",
// }) => {
//   // states
//   const [showSorting, setShowSorting] = useState(false);
//   const [list, setList] = useState([]);
//   const {page,limit} = useSelector(state=>state.tablePagination)
//   const dispatch = useDispatch();


//   useEffect(() => {
//     if (data.length > 0) {
//       setList(data);
//     } else{
//       setList([])
//     }
//   }, [data]);

// // useEffect(()=>{
// //   dispatch(changeLimit(25))
// //   dispatch(changePage(1))
// // },[])

//   const handleSort = (order, property, type) => {
//     try {
//       setShowSorting(false);
//       if (!list || list.length === 0) {
//         return;
//       }
  
//       const sortedArr = [...list];
  
//       sortedArr.sort((a, b) => {
//         if (type === "string") {
//           const valueA = a[property];
//           const valueB = b[property];
//           return order === "asc" ?  valueA.localeCompare(valueB) :  valueB.localeCompare(valueA);
//         }
//         return 0; 
//       });
//       setList(sortedArr);
//     } catch (error) {
//       console.error("Error sorting list:", error);
//     }
//   };
  

//   const handlePrevPage = () => {
//     if (page <= 1) {
//       return;
//     } else {
//       dispatch(changePage(page-1))
//     }
//   };

//   const handleNextPage = () => {
   
//     if (page === totalPages) {
//       return;
//     } else {
//       dispatch(changePage(page+1))
//     }
//   };

//   return (
//     <div className="table-container">
//       <table className="main-table">
//         <thead>
//           <tr>
//             <th style={{minWidth:80}}>S.No</th>
//             {column.map((val, i) => {
//               return val.sorting ? (
//                 <th key={i} className="sorting-row">
//                   {val?.headerName}
//                   <AiFillCaretDown
//                     style={{
//                       transform: showSorting ? "rotate(180deg)" : "",

//                       transition: "0.3s",
//                     }}
//                     onClick={() => setShowSorting(!showSorting)}
//                   />
//                   <div
//                     className="sorting-option"
//                     style={{
//                       height: showSorting ? "100px" : "0px",
//                     }}
//                   >
//                     <li
//                       key={"asc"}
//                       onClick={() => handleSort("asc", val?.property, val?.type)}
//                     >
//                       {" "}
//                       Sort A- Z
//                     </li>
//                     <li
//                       key={"dsc"}
//                       onClick={() => handleSort("desc",val?.property, val?.type)}
//                     >
//                       Sort Z- A
//                     </li>
//                   </div>
//                 </th>
//               ) : (
//                 <th key={i}>{val?.headerName}</th>
//               );
//             })}
//             {viewButton && <th>Action</th>}
//           </tr>
//         </thead>
//         <tbody>
//           {list
//             ?.map((val, i) => (
//               <tr key={i}>
//                 <td>{limit * (page - 1) + i + 1}</td>
//                 {column.map((content, i) => (
//                   <td key={i} style={{minWidth: content?.minWidth}}>
//                     {content.type === "image" ? (
//                       <div className="user-pic">
//                         {val.dp ? (
//                           <img src={val.dp} alt="" />
//                         ) : (
//                           <img src="/images/profile.jpg" alt="" />
//                         )}
//                       </div>
//                     ) : (
//                       content.type === "freePlanLogs" ? <span>{val[content.property] ? "Active" :"Inactive" }</span>: content.type === "gender" ? <span>{val[content.property] === 1 ? "Female" :val[content.property] === 2 ? "Male": "Ohers" }</span>
//                      :  content.type === "object" ? <span>{val[content.property] }</span>: <span>{val[content.property]}</span> 
//                     )}
//                   </td>
//                 ))}
//                 {
//                   viewButton && <td style={{minWidth:100}}> 
//                   <Link to={`${redirectTo}/${val[redirect]}?${query}`} target="blank">
//                     <button className="btn-know">View</button>
//                   </Link>
//                 </td>
//                 }
//               </tr>
//             ))}
//         </tbody>
//       </table>

//       {/* No data image */}
//       {!loading && list.length === 0 && (
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <img
//             style={{
//               width: "30%",
//             }}
//             src={noDataImage}
//             alt="no data"
//           />
//         </div>
//       )}

//       {/* Pagination */}
//       <div className="load-more-div">
//         <div className="rows-div">
//           <select
//             name="rowsNumber"
//             onChange={(e) => dispatch(changeLimit((Number(e.target.value))))}
//             style={{
//               width: "60px",
//               height: "30px",
//               marginRight: "20px",
//             }}
//           >
//             <option value="25">25</option>
//             <option value="50">50</option>
//             <option value="100">100</option>
//           </select>
//         </div>
//         <div className="page-number-div">
//           <button className="page-btn" onClick={handlePrevPage}>
//             Prev
//           </button>
//           {page} of {totalPages}
//           <button className="page-btn" onClick={handleNextPage}>
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Table;

import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import noDataImage from "../assets/nodata.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeLimit, changePage } from "../redux/table/pagination";

const Table = ({
  data = [],
  column = [],
  loading = true,
  redirect = "_id",
  totalPages = 1,
  viewButton = true,
  query = "",
  redirectTo = "/viewmore",
  editButton = false, // New prop to handle edit button
  editRedirectTo = "", // New prop for custom edit link
}) => {
  const [showSorting, setShowSorting] = useState(false);
  const [list, setList] = useState([]);
  const { page, limit } = useSelector(state => state.tablePagination);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length > 0) {
      setList(data);
    } else {
      setList([]);
    }
  }, [data]);

  const handleSort = (order, property, type) => {
    try {
      setShowSorting(false);
      if (!list || list.length === 0) {
        return;
      }

      const sortedArr = [...list];

      sortedArr.sort((a, b) => {
        if (type === "string") {
          const valueA = a[property];
          const valueB = b[property];
          return order === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
        return 0;
      });
      setList(sortedArr);
    } catch (error) {
      console.error("Error sorting list:", error);
    }
  };

  const handlePrevPage = () => {
    if (page <= 1) {
      return;
    } else {
      dispatch(changePage(page - 1));
    }
  };

  const handleNextPage = () => {
    if (page === totalPages) {
      return;
    } else {
      dispatch(changePage(page + 1));
    }
  };

  const getNestedValue = (obj, property) => {

    const keys = property.split('.');
    console.log('keys',keys)

    // Reduce the keys to traverse the object
    const value = keys.reduce((acc, key) => acc && acc[key], obj);

    console.log('getNestedValue - Result:', value);
    return value;
};


  return (
    <div className="table-container">
      <table className="main-table">
        <thead>
          <tr>
            <th style={{ minWidth: 80 }}>S.No</th>
            {column.map((val, i) => (
              val.sorting ? (
                <th key={i} className="sorting-row">
                  {val?.headerName}
                  <AiFillCaretDown
                    style={{
                      transform: showSorting ? "rotate(180deg)" : "",
                      transition: "0.3s",
                    }}
                    onClick={() => setShowSorting(!showSorting)}
                  />
                  <div
                    className="sorting-option"
                    style={{
                      height: showSorting ? "100px" : "0px",
                    }}
                  >
                    <li
                      key={"asc"}
                      onClick={() => handleSort("asc", val?.property, val?.type)}
                    >
                      {" "}
                      Sort A- Z
                    </li>
                    <li
                      key={"dsc"}
                      onClick={() => handleSort("desc", val?.property, val?.type)}
                    >
                      Sort Z- A
                    </li>
                  </div>
                </th>
              ) : (
                <th key={i}>{val?.headerName}</th>
              )
            ))}
            {(viewButton || editButton) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {list.map((val, i) => (
            <tr key={i}>
              <td>{limit * (page - 1) + i + 1}</td>
              {column.map((content, i) => (
                <td key={i} style={{ minWidth: content?.minWidth }}>
                  {content.type === "image" ? (
                    <div className="user-pic">
                      {val.dp ? (
                        <img src={val.dp} alt="" />
                      ) : (
                        <img src="/images/profile.jpg" alt="" />
                      )}
                    </div>
                  ) :content.type === "objectValue" ? (
                    <span>{getNestedValue(val, content.property) || "N/A"}</span>
                  ): content.type === "freePlanLogs" ? (
                    <span>{val[content.property] ? "Active" : "Inactive"}</span>
                  ) : content.type === "gender" ? (
                    <span>
                      {val[content.property] === 1
                        ? "Female"
                        : val[content.property] === 2
                        ? "Male"
                        : "Others"}
                    </span>
                  ):content.type === "bigImage" ? (
                    <div className="big-image">
                      {val.image ? (
                        <img src={val.image} alt="" />
                      ) : (
                        <img src="/images/profile.jpg" alt="" />
                      )}
                    </div>
                  ) 
                  : 
                  Array.isArray(val[content.property]) ? (
                    <div>
                      {val[content.property].map((item, index) => (
                        <div key={index}>
                          {index + 1}: {item}
                        </div>
                      ))}
                    </div>
                  ):
                   typeof val[content.property] === "boolean" ? (
                    <span>{val[content.property] ? "Yes" : "No"}</span>
                  )
                  :content.type === "html" ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: val[content.property] }}
                    />
                  ) : (
                    <span>{val[content.property]}</span>
                  )}
                </td>
              ))}
              {(viewButton || editButton) && (
                <td style={{ minWidth: 100 }}>
                  {viewButton && (
                    <Link to={`${redirectTo}/${val[redirect]}?${query}`} target="_blank">
                      <button className="btn-know">View</button>
                    </Link>
                  )}
                  {editButton && (
                    <Link to={`${editRedirectTo}/${val[redirect]}?${query}`} target="_blank">
                      <button className="btn-know">Edit</button>
                    </Link>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {!loading && list.length === 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "30%",
            }}
            src={noDataImage}
            alt="no data"
          />
        </div>
      )}

      <div className="load-more-div">
        <div className="rows-div">
          <select
            name="rowsNumber"
            onChange={(e) => dispatch(changeLimit(Number(e.target.value)))}
            style={{
              width: "60px",
              height: "30px",
              marginRight: "20px",
            }}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div className="page-number-div">
          <button className="page-btn" onClick={handlePrevPage}>
            Prev
          </button>
          {page} of {totalPages}
          <button className="page-btn" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;