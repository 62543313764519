import React, { useEffect } from "react";
import { MdVerified } from "react-icons/md";
import { BiSolidErrorCircle } from "react-icons/bi";
import { motion } from "framer-motion";
export const Toast = ({ open, setOpen, message, status }) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => setOpen(false), 2000);

    return () => clearTimeout(timeoutId);
  }, [open]);

  return (
    <>
      {open && (
        <motion.div
          initial={{
            y: -100,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            type: "spring",
            stiffness: 150,
          }}
          className="toast-body"
          style={{
            position: "fixed",
            left: "40%",
            top: "5%",
            transform: "translate(-50%,-5%)",
            zIndex: 200003003,
            paddingInline: "15px",
            paddingBlock: "15px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            background: "white",
            boxShadow:"0px 5px 5px rgba(0,0,0,0.4)"
          }}
        >
          {status === 200 ? (
            <MdVerified
              style={{
                color: "green",
                fontSize: "1.8rem",
              }}
            />
          ) : (
            <BiSolidErrorCircle
              style={{
                color: "red",
                fontSize: "1.8rem",
              }}
            />
          )}
          {message}
        </motion.div>
      )}
    </>
  );
};
