import React from "react";
import Logo from "../assets/logo.png";

const FallBackLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        background:"white"
      }}
    >
      <img width={"100px"} src={Logo} alt="Logo" />
      <br />
      <h3>Loading ....</h3>
    </div>
  );
};

export default FallBackLoader;
