import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../config/authAxios";


const initialState = { message: "", data: [], status: null, loading: false };
export const getDashboardStatsAction = createAsyncThunk(
  "dashboard",
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(`/dashboard-stats`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getDashboardStatsSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardStatsAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getDashboardStatsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(getDashboardStatsAction.rejected, (state, action) => {
      state.loading = false;

      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const getDashboardStatsReducer = getDashboardStatsSlice.reducer;
