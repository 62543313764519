import React, { useState, useRef, useMemo } from "react";
import "./style.css";
import Header from "../../components/Header";
import JoditEditor from "jodit-react";

import { Card, CardContent, CircularProgress, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileAction } from "../../redux/reducers/uploadFile";
import { createBlogAction } from "../../redux/reducers/meta/blog/createBlog";
import { Toast } from "../../components/Toast";

const CreateBlog = () => {
const [data, setData] = useState({});
const editor = useRef(null);
const [snake, setSnake] = useState(false);
const [file,setFile] = useState("")
const dispatch = useDispatch();
const {message, status, loading} = useSelector(state=>state.createBlog)
const { loading:imageLoading} = useSelector(state=>state.uploadFile)

const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setData((prev) => ({ ...prev, [name]: e.target.files[0] }));
    } else {
      setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        const formData = new FormData();
        formData.set("file", file);
       const res =  await dispatch(uploadFileAction(formData));

       if(res?.payload?.status !== 200) {
         return
       }
       data.image = res?.payload?.fileUrl
    }
        if(Object.keys(data).length === 0 || Object.values(data).length === 0) {
            console.log(Object.values(data).length )
            return
        }
        await dispatch(createBlogAction(data))
        setSnake(true)

    } catch (error) {
        console.log(error)
    }
  }

 
    

  return (
    <>
      <Header />
      <div className="container-2">
        <h3>Create Blog</h3>
        <br />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            gap: "15px",
            flexWrap: "wrap",
            // background:"red"
          }}
        >
          <div className="input-box">
            <label htmlFor="title">Author</label>
            <TextField
              name="author"
              onChange={handleInputChange}
              fullWidth
              label="Author"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Category</label>
            <TextField
              name="category"
              onChange={handleInputChange}
              fullWidth
              label="category"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Reading Time</label>
            <TextField
              fullWidth
              name="readingTime"
              onChange={handleInputChange}
              label="Reading Time"
              type="number"
              variant="outlined"
              size="small"
            />
          </div>

          <div className="input-box">
            <label htmlFor="title">Title</label>
            <TextField
              onChange={handleInputChange}
              name="title"
              fullWidth
              label="Tilte"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Meta Description</label>
            <TextField
              onChange={handleInputChange}
              name="metaDescription"
              fullWidth
              label="Meta Description"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Meta KeyWords</label>
            <TextField
              name="metaKeyWords"
              onChange={handleInputChange}
              fullWidth
              label="Meta KeyWords"
              type="text"
              variant="outlined"
              size="small"
            />
          </div>
          <div className="input-box">
            <label htmlFor="title">Image</label>
            <TextField
            
              onChange={(e)=>setFile(e.target.files[0])}
              inputProps={{
                accept: "image/*",
              }}
              fullWidth
              type="file"
              variant="outlined"
              size="small"
            />
          </div>
        </Stack>

        <Card
          elevation={3}
          sx={{ width: "100%", mt: 5, background: "whitesmoke" }}
        >
          <CardContent>
            <h3>Small Description</h3>
            <JoditEditor
              ref={editor}
              value={data?.smallDescription}
              config={config}
              tabIndex={1} // tabIndex of textarea
              // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onBlur={(newContent) =>
                setData((prev) => ({ ...prev, smallDescription: newContent }))
              }
            />
          </CardContent>
        </Card>

        <Card
          elevation={3}
          sx={{ width: "100%", mt: 5, background: "whitesmoke" }}
        >
          <CardContent>
            <h3>Content</h3>
            <JoditEditor
              ref={editor}
              value={data?.content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              // preferred to use only this option to update the content for performance reasons
              onBlur={(newContent) => {
                setData((prev) => ({ ...prev, content: newContent }));
              }}
            />
          </CardContent>
        </Card>
        <div className="submit-btn-box">
          <button
          disabled={loading}
            className="btn-submit"
            onClick={handleSubmit}
          >
          {loading  || imageLoading ? <CircularProgress size={20} color="inherit"/> : "Submit"}
          </button>
        </div>
      </div>

      <Toast open={snake} setOpen={setSnake} message={message} status={status}/>
    </>
  );
};

export default CreateBlog;
