import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Table from "../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { freePlanLogsAction } from "../redux/reducers/meta/freePlanLogs";
import {BigLoader} from '../components/BigLoader'
import { Box, Button, Card, CardContent } from "@mui/material";
import { getAdminLogsAction } from "../redux/reducers/admin/authLogs";
import { getAdminListAction } from "../redux/reducers/admin/adminList";
import CreateAdmin from "./CreateAdmin";
const AllAdmin = () => {
    const [formData,setFormdata] = useState({})
    const [showCreateAdmin,setShowCreateAdmin] = useState(false)
  const dispatch = useDispatch();
  const {limit ,page} = useSelector(state=>state.tablePagination)
  const { data, loading } = useSelector((state) => state.adminList);
  useEffect(() => {
    setFormdata({
      ...formData,
      limit:limit,
      page:page
    })
    dispatch(getAdminListAction(formData));
  }, [limit,page]);

  const column =[
    {
        headerName: "AdminID",
        property: "_id",
        minWidth:200
      },
      {
        headerName: "Email",
        property: "email",
        minWidth:300
      },
      {
        headerName: "Name",
        property: "name",
        minWidth:200
      },
      {
        headerName: "Last Login",
        property: "lastLoginAt",
        minWidth:200
      },
      {
        headerName: "Registred On",
        property: "createdAt",
        minWidth:200
      },
  ]
  return (
    <>
      <Header selectedNumber={4}></Header>
      <div className="container-2">
        <Card sx={{mt:4,bgcolor:"whitesmoke"}}>
          <CardContent>
            <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            <h3>Free Plan Logs</h3>
            <Button onClick={()=>setShowCreateAdmin(true)} startIcon ={"+"}>Create Admin</Button>
            </Box>
          </CardContent>
        </Card>
        <Table redirectTo="/viewmore/admin" data={data?.logs} viewButton ={true} column={column} loading={loading} totalPages={data?.totalPages} />
      </div>
      <CreateAdmin open={showCreateAdmin} setOpen={setShowCreateAdmin}/>
      {loading && <BigLoader/>}
    </>
  );
};

export default AllAdmin;
