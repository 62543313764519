import React, { useState } from "react";
import "../Styles/header.css";
import CustomLink from "./CustomLink";
import { FaUserFriends } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";
import { RxDashboard } from "react-icons/rx";
import { MdAdminPanelSettings } from "react-icons/md";
import { BsMeta } from "react-icons/bs";
import { FaBlog } from 'react-icons/fa';
import UpperHeader from "./UpperHeader";

const Header = ({ selectedNumber }) => {
  const [selectedFaq, setSelectedFaq] = useState();
  const [menuWidth, setMenuWidth] = useState("250px");

  const showContent = (val) => {
    setSelectedFaq(val);
  };
  
  const getPermission = localStorage.getItem("permission") || [];
  const admin = localStorage.getItem("superAdmin") ||  2;

  const openMenu = () => {
    setMenuWidth("250px");
    selectedNumber = null;
  };


  const content = [
    {
      title: "Home",
      permission:true,
      path: "/home",
      icon: <RxDashboard>,</RxDashboard>,
      nested: false,
      content: [],
    },
    {
      title: "Meta Data",
      icon: <BsMeta>,</BsMeta>,
      nested: true,
      permission:Number(admin) === 1 || (["meta","freeplan-logs","refer"].some((item) => getPermission.includes(item))) ? true : false,
    
      content: [
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("meta")) ? true : false,
          name: "General",
          path: "/general",
        },
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("freeplan-logs")) ? true : false,
          name: "Free Plan Logs",
          path: "/free-plan-logs",
        },
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("refer")) ? true : false,
          name: "Referal",
          path: "/refer",
        },
      ],
    },
    {
      title: "User",
      icon: <FaUserFriends>,</FaUserFriends>,
      nested: true,
      permission:Number(admin) === 1 || (["deleted-users","active-users","all-users","reported-users"].some((item) => getPermission.includes(item))) ? true : false,
      content: [
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("deleted-users")) ? true : false,
          name:"Deleted Users",
          path:"/deleted/users"
        },
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("active-users")) ? true : false,
          name:"Active Users",
          path:"/active/users"
        },
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("all-users")) ? true : false,
          name: "User Details",
          path: "/users",
        },
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("reported-users")) ? true : false,
          name: "Reported Users",
          path: "/report",
        },
      ],
    },
    {
      title: "Subcription",
      icon: <FaIndianRupeeSign>,</FaIndianRupeeSign>,
      nested: true,
      permission:Number(admin) === 1 || (["active-premium","premium-history"].some((item) => getPermission.includes(item))) ? true : false,
      content: [
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("active-premium")) ? true : false,
          name: "Active Premium",
          path: "/active-subscription",
        },
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("premium-history")) ? true : false,
          name: "Premium History",
          path: "/premiumuser",
        },
      ],
    },
    {
      title: "Blogs List",
      permission:true,
      path: "/view/all/blog",
      icon: <FaBlog></FaBlog>,
      nested: false,
      content: [],
    },

    {
      permission:Number(admin) === 1 || (["auth-logs","admin"].some((item) => getPermission.includes(item))) ? true : false,
      title: "Admin",
      icon: <MdAdminPanelSettings/>,
      nested: true,
      content: [
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("auth-logs")) ? true : false,
          name: "Admin Logs",
          path: "/admin-logs",
        },
        {
          permission:Number(admin) === 1 || (getPermission && getPermission.includes("admin")) ? true : false,
          name: "Admins List",
          path: "/all-admin",
        },
      ],
    },
  ];

  return (
    <>
      <div>
        <UpperHeader></UpperHeader>
        <div
          className="left-header"
          style={{
            width: menuWidth,
          }}
        >
          <div className="menu-container">
            <div className="title-menu">
              <h4>Admin Panel</h4>
            </div>
            {content.map((val, i) => (
           val.permission ?    <CustomLink
           arrow={<HiOutlineChevronDown></HiOutlineChevronDown>}
           nested={val.nested}
           key={i}
           slectedFaq={selectedFaq}
           title={val.title}
           content={val.content.map((head, j) => (
            head.permission ?  <NavLink
            key={j}
            to={head.path}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : "pending"
            }
          >
            {head.name}
          </NavLink> : null
           ))}
           titleNumber={i}
           showContent={() => showContent(i)}
           icon={val.icon}
           path={val.path}
           selectedNumber={selectedNumber}
         ></CustomLink> : null
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
