import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../config/authAxios";

const initialState = { message: "", data: [], status: null, loading: false };
export const sendNotificationAction = createAsyncThunk(
  "send-notification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(`/sendnotification`,{...data?.requestData});
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const sendNotificationSlice = createSlice({
  name: "send-notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendNotificationAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sendNotificationAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(sendNotificationAction.rejected, (state, action) => {
      state.loading = false;

      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const sendNotificationReducer = sendNotificationSlice.reducer;
