import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { freePlanLogsAction } from "../../redux/reducers/meta/freePlanLogs";
import {BigLoader} from '../../components/BigLoader'
import { Button, Card, CardContent } from "@mui/material";
import { getAllReferAction } from "../../redux/reducers/meta/getAllRefer";
import CreateReferLink from "./CreateReferLink";
const Referals = () => {
  const [opneReferModal,setOpenReferModal] = useState(false)
  const dispatch = useDispatch();
  const {limit ,page} = useSelector(state=>state.tablePagination)
  const { data, loading } = useSelector((state) => state.allRefer);
  useEffect(() => {
    dispatch(getAllReferAction({limit,page}));
  }, [limit,page]);

  const column =[ 
    {
        headerName: "Name",
        property: "influencerName",
        minWidth:200
      },
      {
        headerName: "Refer Code",
        property: "referCode",
        minWidth:100
      },
      {
        headerName: "Clicks",
        property: "clicks",
        minWidth:100
      },
      {
        headerName: "Installations",
        property: "installations",
        minWidth:150
      },
      {
        headerName: "Refer Link",
        property: "customLink",
        minWidth:300
      },
      {
        headerName: "Redirection Link",
        property: "destinationLink",
        minWidth:400
      },
      {
        headerName: "Created At",
        property: "createdAt",
        minWidth:400
      },
      
  ]
  return (
    <>
      <Header selectedNumber={1}></Header>
      <div className="container-2">
        <Card sx={{mt:4,bgcolor:"whitesmoke"}}>
          <CardContent sx={{flexDirection:"row",display:"flex",justifyContent:"space-between"}}>
            <h3>Refer And Installations Analysis</h3>
            <Button startIcon ={"+"} onClick={()=>setOpenReferModal(true)}>Refer</Button>
          </CardContent>
        </Card>
        <Table data={data} viewButton ={false} column={column} loading={loading} totalPages={1} />
      </div>
      {loading && <BigLoader/>}
      <CreateReferLink open={opneReferModal} setOpen={setOpenReferModal}/>
    </>
  );
};

export default Referals;
