import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  import { TiDelete } from "react-icons/ti";
  import { useDispatch, useSelector } from "react-redux";
  import Loader from "../components/Loader";
  import { Toast } from "../components/Toast";
  import { addAdminPermissionAction } from "../redux/reducers/admin/addPermission";
import { createAdminAction } from "../redux/reducers/admin/createAdmin";
import { getAdminListAction } from "../redux/reducers/admin/adminList";
  
  
  const CreateAdmin = ({ open, setOpen ,adminId}) => {
    const [snake, setSnake] = useState(false);
    const [permission,setPermission] = useState([]);
    const [formData, setFormData] = useState({})
    const { message, status, loading } = useSelector(
      (state) => state.createAdmin
    );

    const dispatch = useDispatch();
  
    const handleInputChange = (e) => {
      handleAddPermission(e.target.value.toString().toLowerCase())
    }
  
    const handleAddPermission = (value) => {
      if (!value) {
        return;
      }
      if(permission.includes(value)){
        return
      }
      setPermission((prev)=>[...prev,value])
    
    }
  
    
    const submitPermissions = async () => {
        if(permission.length === 0){
     
          return
        }
        if(!formData?.name){
            return
        }
        if(!formData?.password){
          return;
        }
        if(!formData?.email){
          return;
        }
        console.log("w")
        formData.authorisedLevel = permission;  

    await dispatch(createAdminAction(formData));
    await dispatch(getAdminListAction());
      setSnake(true);
      setOpen(false);
    };
  
  
    return (
      <>
        <div>
          <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <Alert sx={{ justifyContent: "center" }} severity="error">
              All Fields are Required to create the Admin.
            </Alert>
              <DialogTitle>
                <h4>Create Admin </h4>
              </DialogTitle>
              <DialogContent>
                <Box display="flex" flexDirection="column" gap={1} p={2}>
                  <Box>
                    { permission.length > 0 && permission.map((item,index) => <span  key={index} style={{marginRight:"10px",fontSize:"15px"}}>{item} <TiDelete style={{fontSize:"1.2rem",cursor:"pointer"}} onClick={() => setPermission((prev)=>prev.filter((i)=>i !== item))} /></span>) }
                  </Box>
                  <Box marginTop={2}>
                  <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  label="Permissions"
                  name="Permission"
                  onChange={handleInputChange}
                >
                     <MenuItem value={"meta"}>Meta</MenuItem>
                  <MenuItem value={"all-users"}>All Users</MenuItem>
                  <MenuItem value={"view-users"}>View Users</MenuItem>
                  <MenuItem value={"view-admin"}>View Admin</MenuItem>
                  <MenuItem value={"reported-users"}>Reported Users</MenuItem>
                  <MenuItem value={"premium-history"}>Premium History</MenuItem>
                  <MenuItem value={"active-premium"}>Active Premium</MenuItem>
                  <MenuItem value={"active-users"}>Active User</MenuItem>
                  <MenuItem value={"deleted-users"}>Deleted Users</MenuItem>
                  <MenuItem value={"freeplan-logs"}>Free Plan Logs</MenuItem>
                  <MenuItem value={"auth-logs"}>Auth Logs</MenuItem>
                  <MenuItem value={"refer"}>Refer</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                </Select>
              </FormControl>
                  </Box>
                 
                  <TextField  
                    fullWidth
                    required
                    onChange={(e)=>setFormData((prev)=>({...prev,email:e.target.value}))}
                    name="emailId"
                    label="Email Id"
                  />
                  <TextField  
                   required
                    fullWidth
                    onChange={(e)=>setFormData((prev)=>({...prev,name:e.target.value}))}
                    name="name"
                    label="Name"
                  />
                   <TextField  
                   required
                    fullWidth
                    onChange={(e)=>setFormData((prev)=>({...prev,password:e.target.value}))}
                    name="password"
                    label="Password"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <button
                  onClick={submitPermissions}
                  className="btn-submit"
                  style={{ width: "120px" }}
                  disabled={loading}
                >
                  {loading ? <Loader /> : "Submit"}
                </button>
  
                <button
                  className="btn-submit"
                  disabled={loading}
                  style={{ width: "120px", background: "red" }}
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </DialogActions>
          </Dialog>
        </div>
        <Toast
          open={snake}
          setOpen={setSnake}
          message={message}
          status={status}
        />
      </>
    );
  };
  
  export default CreateAdmin;
  