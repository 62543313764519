import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../../config/authAxios";

const initialState = { message: "", data: [], status: null, loading: false };
export const getSingleBlogAction = createAsyncThunk(
  "blog/getSingleBlog",
  async (blogId, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(`/get/blog/${blogId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getSingleBlogSlice = createSlice({
  name: "getSingleBlog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSingleBlogAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getSingleBlogAction.fulfilled, (state, action) => {
      state.blog = action.payload;
      state.loading = false;
    });

    builder.addCase(getSingleBlogAction.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export const getSingleBlogReducer = getSingleBlogSlice.reducer;