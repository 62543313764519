import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../config/authAxios";

const initialState = { message: "", data: [], status: null, loading: false };
export const getUserSubscriptionHistoryAction = createAsyncThunk(
  "user-subscription",
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(`/user-subscription?userId=${data}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getUserSubscriptionHistorySlice = createSlice({
  name: "user-subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserSubscriptionHistoryAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserSubscriptionHistoryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(getUserSubscriptionHistoryAction.rejected, (state, action) => {
      state.loading = false;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const getUserSubscriptionHistoryReducer = getUserSubscriptionHistorySlice.reducer;
