import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../../config/authAxios";


const initialState = { message: "", data: [], status: null, error: "" };
export const createBlogAction = createAsyncThunk(
  "create-blog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(
        `/create/blog`,
        { ...data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createBlogSlice = createSlice({
  name: "create-blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createBlogAction.pending, (state) => {
      state.loading = true
    });

    builder.addCase(createBlogAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(createBlogAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const createBlogReducer = createBlogSlice.reducer;
