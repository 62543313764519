import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { FaMoneyBill, FaUsers } from "react-icons/fa";
import { BiSolidDownload } from "react-icons/bi";
import "../Styles/alluser.css";
import Table from "../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { getDeletedUsersAction } from "../redux/reducers/user/deletedUsers";
import Loader from "../components/Loader";
import { BigLoader } from "../components/BigLoader";
import { changeLimit, changePage } from "../redux/table/pagination";

const DeletedUsers = () => {

  const [formData, setFormData] = useState({});
  const [snake, setSnake] = useState(false);

  const dispatch = useDispatch();
  const {page,limit} = useSelector(state=>state.tablePagination)
  const {data,loading,message} = useSelector(state=>state.getDeletedUsers)

  useEffect(()=>{
    if(Object.keys(formData).length === 0 || Object.values(formData).length === 0)
    dispatch(getDeletedUsersAction());
  },[page,limit])

  const resetPage = ()=>{
    dispatch(changeLimit(25))
    dispatch(changePage(1))
  }
  useEffect(()=>{
    resetPage()
  },[])
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prev) => ({
      ...prev,
      [name]: inputValue,
    }));
  };

  const handleReset = () => {
    setFormData({});
    resetPage()
    dispatch(getDeletedUsersAction())
  };

  const handleFilter = async()=>{
    resetPage()
    await dispatch(getDeletedUsersAction({page,limit,formData}))
    setSnake(true)
  }


  const column = [
    {
      headerName: "PI",
      type: "image",
      property: "",
    },
    {
      headerName: "Gender",
      type: "gender",
      property: "gender",
    },
    {
      headerName: "User Id",
      type: "",
      property: "_id",
    },

    {
      headerName: "Device Id",
      type: "",
      property: "deviceId",
    },

    {
      headerName: "Name",
      type: "string",
      property: "name",
      sorting: true,
    },
    {
      headerName: "Time",
      type: "updatedAt",
      property: "updatedAt",
    },
  ];
  return (
    <>
      <Header selectedNumber={2}></Header>
      <div className="container-2">
        <div className="filter-option" style={{ padding: 20 }}>
          <div className="total-premium total-users" style={{ minWidth: 300 }}>
            <h4 className="icon-heading">
              <FaMoneyBill></FaMoneyBill> Total Deleted Users
            </h4>
            <p
              className="user-numbers"
              style={{
                opacity: 0.5,
              }}
            >
              {data?.totalDeletedUsers || 0}
            </p>
            <p
              style={{
                opacity: 0.8,
              }}
            >
              Till - {new Date().toISOString().split("T", 1)}
            </p>
          </div>
          <div className="date-selection">
            <div className="left-filter">
              <div className="str-date">
                <label htmlFor="date">Starting Date</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate || ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="str-date">
                <label htmlFor="date">End Date</label>
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate || ""}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="prem-selection">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <label htmlFor="blocked">Blocked</label>
                  <input
                    type="checkbox"
                    name="blocked"
                    value={formData.blocked || false}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <button className="btn-submit btn-csv">
                  CSV <BiSolidDownload />
                </button>
              </div>
            </div>

            <div className="right-filter">
              <div className="name-select">
                <label htmlFor="name">User Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name || ""}
                  placeholder="Enter name.."
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="name-select">
                <label htmlFor="name">Device Id</label>
                <input
                  type="text"
                  name="deviceId"
                  value={formData.deviceId || ""}
                  placeholder="Enter ID"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="btn-filter-div">
                <button className="filter-btn reset-btn" onClick={handleReset}>
                  Reset
                </button>
                <button
                  className="filter-btn"
                    disabled={loading}
                    onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <Table query="deleted=true" column={column} data={data?.filteredUsers} totalPages={data?.totalPages} loading = {loading}/>
      </div>
      {loading && <BigLoader/>}
    </>
  );
};

export default DeletedUsers;
