import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../config/authAxios";

// Initial state with consistent fields
const initialState = { message: "", data: [], status: null, loading: false };

export const getUserStatsAction = createAsyncThunk(
  "get-user-stats",
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(`user-stats/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data ? error.response.data : error.message
      );
    }
  }
);

const getUserStatsSlice = createSlice({
  name: "get-user-stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserStatsAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserStatsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data || [];
      state.status = action.payload.status || "success";
      state.message = action.payload.message || "Data fetched successfully";
    });

    builder.addCase(getUserStatsAction.rejected, (state, action) => {
      state.loading = false;
      state.status = action.payload?.status || "error";
      state.message = action.payload?.message || "Failed to fetch data";
    });
  },
});

export const getUserStatsReducer = getUserStatsSlice.reducer;
